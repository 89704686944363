import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import Logo from 'icons/200_East_75th_Street_Condominiums.svg'

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${calculateResponsiveSize(30)} ${calculateResponsiveSize(45)};
  font-family: "Avenir Next";
  color: #000000;
  font-size: ${calculateResponsiveSize(10)};
  line-height: 1;
  letter-spacing: ${calculateResponsiveSize(2)};
  text-transform: uppercase;
  border-top: 1px solid #000000;
  position: relative;
  &.gray,
  &.blue {
    color: #fff6f0;
    border-color: #fff6f0;
    a {
      color: #fff6f0;
    }
  }
  .gray &,
  .blue & {
    color: #fff6f0;
    border-color: #fff6f0;
    a {
      color: #fff6f0;
    }
  }
  ${mediaMax('desktopLarge')} {
    padding: 30px 45px;
    font-size: 10px;
    letter-spacing: 2px;
  }
  ${mediaMax('tablet')} {
    padding: 42px 24px;
  }
`

export const FooterLogo = styled((props) => <Link {...props} />)`
  position: absolute;
  top: ${calculateResponsiveSize(30)};
  left: ${calculateResponsiveSize(42)};
  width: ${calculateResponsiveSize(63)};
  height: ${calculateResponsiveSize(82)};
  background: #000000;
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${Logo});
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-image: url(${Logo});
  .gray &,
  .blue & {
    background: #fff6f0;
  }
  ${mediaMax('desktopLarge')} {
    top: 30px;
    left: 42px;
    width: 63px;
    height: 82px;
  }
  ${mediaMax('tablet')} {
    position: static;
    width: 57px;
    height: 74px;
    margin-bottom: 36px;
  }
`

export const FooterContacts = styled.address`
  display: flex;
  gap: 24px;
  margin-bottom: ${calculateResponsiveSize(18)};
  text-align: center;
  font-weight: 700;
  font-style: normal;
  &:not(.first) {
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }

  ${mediaMax('desktopLarge')} {
    margin-bottom: 18px;
  }
  ${mediaMax('tablet')} {
    margin-bottom: 26px;
  }
`

export const FooterLinks = styled.div`
  display: flex;
  gap: ${calculateResponsiveSize(18)};
  ${mediaMax('desktopLarge')} {
    gap: 18px;
  }

  ${mediaMax('tablet')} {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
`

export const LegalLink = styled((props) => <Link {...props} />)``

export const FooterLinkItem = styled.a``
