import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import IcoClose from 'icons/ico_close.svg'

export const MenuWrap = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: ${calculateResponsiveSize(30)} 0;
  color: #fff6f0;
  text-transform: uppercase;
  border-left: 1px solid #fff6f0;
  background: rgba(0, 0, 0, 0.35);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  transform: translateX(100%);
  transition: 1s;
  z-index: 20;
  ${mediaMax('desktopLarge')} {
    padding: 30px 0;
  }
  ${mediaMax('tablet')} {
    width: 100%;
  }
`

export const CloseButton = styled.div`
  width: ${calculateResponsiveSize(24)};
  height: ${calculateResponsiveSize(24)};
  margin: 0 ${calculateResponsiveSize(30)} 0 auto;
  background: #fff6f0;
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${IcoClose});
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-image: url(${IcoClose});
  cursor: pointer;
  ${mediaMax('desktopLarge')} {
    width: 24px;
    height: 24px;
    margin: 0 30px 0 auto;
  }
  ${mediaMax('tablet')} {
  }
`

export const Primary = styled.ul`
  display: flex;
  flex-direction: column;
  margin: auto 0 0 0;
  padding: 0;
  list-style: none;
  a {
    display: block;
    padding: ${calculateResponsiveSize(18)} ${calculateResponsiveSize(42)};
    text-decoration: none !important;
    font-family: "Arsenica Variable";
    font-size: ${calculateResponsiveSize(16)};
    color: #fff6f0;
    line-height: 0.75;
    letter-spacing: ${calculateResponsiveSize(0.32)};
    text-transform: uppercase;
    &:hover {
      color: #000000;
      background: #fff6f0;
      span{
        color: #000000;
      }
    }
  }
  ${mediaMax('desktopLarge')} {
    a {
      padding: 18px 42px;
      font-size: 16px;
      letter-spacing: 0.32px;
    }
  }
  ${mediaMax('tablet')} {
  }
`

export const Li = styled.li``

export const Item = styled((props) => <Link {...props} />)``

export const Num = styled.span`
  display: block;
  margin-bottom: ${calculateResponsiveSize(6)};
  font-family: "Avenir Next";
  font-size: ${calculateResponsiveSize(10)};
  color: #fff6f0;
  font-weight: 500;
  line-height: 1.2;
  transition: all 0.25s;
  ${mediaMax('desktopLarge')} {
    margin-bottom: 6px;
    font-size: 10px;
  }
`

export const Submenu = styled.ul`
  display: flex;
  flex-direction: column;
  margin: auto 0 0 0;
  padding: 0;
  list-style: none;
  ${mediaMax('tablet')} {
  }
`

export const Secondary = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${calculateResponsiveSize(18)};
  margin: ${calculateResponsiveSize(42)} 0 auto 0;
  padding: 0;
  list-style: none;
  li {
    padding: 0 ${calculateResponsiveSize(42)};
  }
  a {
    text-decoration: none !important;
    font-size: ${calculateResponsiveSize(14)};
    color: #fff6f0;
    line-height: 1.28;
    font-weight: 500;
    letter-spacing: ${calculateResponsiveSize(1)};
    text-transform: uppercase;
  }
  ${mediaMax('desktopLarge')} {
    gap: 18px;
    margin: 42px 0 auto 0;
    li {
      padding: 0 42px;
    }
    a {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
  ${mediaMax('tablet')} {
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  z-index: 19;
`

export const Wrapper = styled.div`
  z-index: 101;
  position: relative;
  &.open {
    ${MenuWrap} {
      transform: translateX(0);
    }
    ${Overlay} {
      opacity: 0.3;
      visibility: visible;
    }
  }

  ${mediaMax('tablet')} {
    &.open {
      ${MenuWrap} {
        transform: translateX(0);
      }
      ${Overlay} {
        display: none;
      }
    }
  }
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${calculateResponsiveSize(12)};
  padding: 0 ${calculateResponsiveSize(25)} 0 ${calculateResponsiveSize(42)};
  a {
    text-decoration: none !important;
    font-size: ${calculateResponsiveSize(10)};
    color: #fff6f0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: ${calculateResponsiveSize(0.4)};
    text-transform: uppercase;
  }
  ${mediaMax('desktopLarge')} {
    gap: 12px;
    padding: 0 25px 0 42px;
    a {
      font-size: 10px;
      letter-spacing: 0.4px;
    }
  }
`

export const LegalLink = styled((props) => <Link {...props} />)``
