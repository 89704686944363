import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  FooterWrapper,
  FooterLogo,
  FooterLinks,
  LegalLink,
  FooterLinkItem,
  FooterContacts
} from './index.styled'

const Footer = ({ layout }) => {
  const {
    dataJson: {
      footerInfo: { email, phone, address1, address2, links }
    }
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          email
          phone
          address1
          address2
          links {
            title
            url
            external
          }
        }
      }
    }
  `)
  return (
    <FooterWrapper id='footer' className={layout}>
      <FooterLogo to='/' alt='200 East 75th Street Condominiums' />
      <FooterContacts className='first'>
        <a href={`mailto:${email}`}>{email}</a>
        <a href={`tel:${phone}`}>{phone}</a>
      </FooterContacts>
      <FooterContacts>
        <div>{address1}</div>
        <div>{address2}</div>
      </FooterContacts>
      <FooterLinks>
        <LegalLink to='/legal'>Legal</LegalLink>
        {links.map((l, i) => {
          return (
            <FooterLinkItem
              key={`links-${i}`}
              href={l.url}
              target='_blank'
              rel='noreferrer'
            >
              {l.title}
            </FooterLinkItem>
          )
        })}
      </FooterLinks>
    </FooterWrapper>
  )
}

export default Footer
