import React from 'react'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'

import { Container, Left, Right } from './index.styled'

const NavigationPrevNext = ({ layout }) => {
  const {
    menuContent: {
      content: { primary, secondary }
    }
  } = useStaticQuery(graphql`
    query MenuQuery {
      menuContent {
        content {
          primary {
            num
            text
            url
            submenu {
              num
              text
              url
            }
          }
          secondary {
            text
            url
          }
        }
      }
    }
  `)
  const links = [...primary, ...secondary]
  const location = useLocation()

  const index = links.findIndex(
    ({ url }) =>
      location.pathname === url ||
      (url !== '/' && location.pathname.startsWith(url))
  )

  if (index === -1) return null

  const prev = index === 0 ? null : links[index - 1]
  const next = index + 1 >= links.length ? null : links[index + 1]

  return (
    <Container
      className={[layout, location.pathname === '/team/' && 'team'].join(' ')}
    >
      {prev ? <Left to={prev.url}>{prev.text}</Left> : <div />}
      {next ? <Right to={next.url}>{next.text}</Right> : <div />}
    </Container>
  )
}

export default NavigationPrevNext
