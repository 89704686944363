import React, { useState, useEffect, useContext } from 'react'
import { FirstLoadContext } from 'helpers/context'
import {
  HeaderWrapper,
  HeaderLogo,
  ButtonContact,
  ButtonMenu
} from './index.styled'
import useWindowSize from 'helpers/use-window-size'

const Header = ({ location, layout }) => {
  const { isFirstLoad } = useContext(FirstLoadContext)
  const [isWhite, setIsWhite] = useState(false)
  const [isMore100vh, setIsMore100vh] = useState(0)
  const [isScrolled, setIsScrolled] = useState(false)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const isHomePage = location.pathname === '/'
  const { isMobile } = useWindowSize()

  useEffect(() => {
    if (isFirstLoad && isHomePage) {
      document.body.classList.add('hidden')

      const removeClassTimeout = setTimeout(() => {
        document.body.classList.remove('hidden')
      }, isMobile ? 4000 : 6000)

      return () => {
        clearTimeout(removeClassTimeout)
      }
    }
  }, [isFirstLoad, isHomePage, isMobile])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset
      const scrolled100vh = window.scrollY > window.innerHeight - 25
      if (isHomePage) {
        setIsWhite(scrolled100vh)
      }
      setIsMore100vh(scrolled100vh)
      setIsScrolled(lastScrollTop > currentScrollTop)
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollTop])

  const onClick = () => {
    const menu = document.querySelector('#menu')
    menu.classList.toggle('open')
  }

  return (
    <HeaderWrapper
      id='header'
      className={[
        layout,
        isFirstLoad && isHomePage && !isMobile ? 'headerFadeIn' : '',
        isHomePage && !isWhite && !isMobile ? 'white' : '',
        isMore100vh ? 'scrolled' : '',
        isScrolled ? 'show' : ''
      ].join(' ')}
    >
      <HeaderLogo to='/' alt='200 East 75th Street Condominiums' />
      <ButtonContact to='/contact'>Contact</ButtonContact>
      <ButtonMenu onClick={onClick}>Menu</ButtonMenu>
    </HeaderWrapper>
  )
}

export default Header
