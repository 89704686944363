import React from 'react'
import { Link } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'

import ArrowLeft from 'icons/arrow_left.svg'
import ArrowRight from 'icons/arrow_right.svg'

export const Button = css`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(12)};
  height: ${calculateResponsiveSize(36)};
  padding: 0 ${calculateResponsiveSize(18)};
  text-decoration: none !important;
  font-family: "Arsenica Variable";
  font-size: ${calculateResponsiveSize(16)};
  color: #fff6f0;
  line-height: 1;
  letter-spacing: ${calculateResponsiveSize(0.32)};
  text-transform: uppercase;
  border-radius: ${calculateResponsiveSize(10)};
  background: #000000;
  transition: all 0.25s;
  &::before {
    display: block;
    width: ${calculateResponsiveSize(30)};
    height: ${calculateResponsiveSize(8)};
    content: "";
    background: #fff6f0;
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: 100% auto;
    mask-image: url(${ArrowLeft});
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100% auto;
    -webkit-mask-image: url(${ArrowLeft});
    transition: all 0.25s;
  }
  &:hover {
    color: #000000;
    background: #fff6f0;
    &::before {
      background: #000000;
    }
  }
  ${mediaMax('desktopLarge')} {
    gap: 12px;
    height: 36px;
    padding: 0 18px;
    font-size: 16px;
    letter-spacing: 0.32px;
    border-radius: 10px;
    &::before {
      width: 30px;
      height: 8px;
    }
  }
  ${mediaMax('tablet')} {
  }
`

export const Left = styled((props) => <Link {...props} />)`
  ${Button}
`

export const Right = styled((props) => <Link {...props} />)`
  ${Button}
  flex-direction: row-reverse;
  &::before {
    mask-image: url(${ArrowRight});
    -webkit-mask-image: url(${ArrowRight});
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${calculateResponsiveSize(1000)};
  margin: 0 auto ${calculateResponsiveSize(108)} auto;
  padding: ${calculateResponsiveSize(108)} ${calculateResponsiveSize(20)} 0 ${calculateResponsiveSize(20)};
  .blue & {
    ${Left}, ${Right} {
      color: #000000;
      background: #fff6f0;
      &::before {
        background: #000000;
      }
      &:hover {
        color: #fff6f0;
        background: #000000;
        &::before {
          background: #fff6f0;
        }
      }
    }
  }
  ${mediaMax('desktopLarge')} {
    max-width: 1000px;
    margin: 0 auto 108px auto;
    padding: 108px 20px 0 20px;
  }
  ${mediaMax('tablet')} {
    flex-direction: column-reverse;
    align-items: center;
    gap: 24px;
  }
`
