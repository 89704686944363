import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import Logo from 'icons/200_East_75th_Street_Condominiums.svg'

export const animationFadeIn = css`
  .headerFadeIn & {
    opacity: 0;
    animation: 2s ease 5.6s normal forwards 1 fadeIn;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: ${calculateResponsiveSize(24)};
  padding: ${calculateResponsiveSize(24)} ${calculateResponsiveSize(25)};
  color: #000000;
  font-size: ${calculateResponsiveSize(14)};
  line-height: 1;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s, transform 1s 0.5s;
  z-index: 10;
  &.white {
    color: #fff6f0;
  }
  &.scrolled {
    transform: translateY(-150%);
    &.show {
      transform: translateY(0);
    }
  }
  ${mediaMax('desktopLarge')} {
    gap: 24px;
    padding: 24px 25px;
    font-size: 14px;
  }
  ${mediaMax('tablet')} {
    position: sticky;
    justify-content: space-between;
    padding: 40px 20px;
    transform: translateY(0);
    background: #fff6f0;
    &.blue {
      background: #8c9ea3;
    }
    &.beige {
      background: #cbc1bb;
    }
    &.gray {
      background: #878280;
    }
  }
`

export const HeaderLogo = styled((props) => <Link {...props} />)`
  position: absolute;
  top: ${calculateResponsiveSize(30)};
  left: ${calculateResponsiveSize(48)};
  width: ${calculateResponsiveSize(59)};
  height: ${calculateResponsiveSize(77)};
  background: #000000;
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${Logo});
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-image: url(${Logo});
  transition: background 0.25s;
  z-index: 2;
  ${animationFadeIn}
  .white & {
    background: #fff6f0;
  }
  .blue & {
    background: #fff6f0;
  }
  .gray & {
    background: #fff6f0;
  }
  ${mediaMax('desktopLarge')} {
    top: 30px;
    left: 48px;
    width: 59px;
    height: 77px;
  }
  ${mediaMax('tablet')} {
    top: 50%;
    left: 50%;
    width: 48px;
    height: 63px;
    transform: translate(-50%, -50%);
  }
`

export const Button = css`
  display: block;
  padding: ${calculateResponsiveSize(12)} ${calculateResponsiveSize(18)};
  text-decoration: none !important;
  font-weight: 500;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  ${animationFadeIn}
  .white & {
    color: #fff6f0;
  }
  .blue & {
    color: #fff6f0;
  }
  .gray & {
    color: #fff6f0;
  }
  transition: color 0.25s, background 0.25s;
  cursor: pointer;
  &:hover {
    color: #000000 !important;
    background: #fff6f0;
  }
  ${mediaMax('desktopLarge')} {
    padding: 12px 18px;
  }
`

export const ButtonContact = styled((props) => <Link {...props} />)`
  ${Button}
`

export const ButtonMenu = styled.div`
  ${Button}
`
