import { createGlobalStyle } from 'styled-components'
import { mediaMax } from 'helpers/media-queries'

const GlobalStyle = createGlobalStyle`
  html, body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #000000;
    line-height: 1.25;
    background: #FFF6F0;
    min-width: 320px;
  }
  .home-content.blue{
    background: #8C9EA3;
  }
  .home-content.beige{
    background: #CBC1BB;
  }
  .home-content.gray{
    background: #878280;
    color: #FFF6F0;
  }
  body{
    overflow-y: scroll;
  }
  body.hidden{
    overflow: hidden;
  }
  html, body, A, input, select, textarea{
    font-family: "Avenir Next", Arial, Tahoma, Geneva, Kalimati, sans-serif;
  }
  A{
    text-decoration: none;
    color: #000000;
    transition: all 0.25s ease 0s;
    outline: none;
  }
  A:hover{
    text-decoration: underline;
  }
  input, select, textarea {
    vertical-align: top;
    outline: none;
  }
  textarea {
    resize: vertical;
  }
  *{ -webkit-tap-highlight-color: transparent; box-sizing: border-box; }
  img, object, iframe, svg{ border: 0; vertical-align: top; }
  svg path, svg circle, svg line{ transition: all 0.25s ease 0s; }
  ${mediaMax('mobileLandscape')} {
    body.landscape {
      max-height: 100%;
      overflow: hidden;
      visibility: hidden;
      background: #8c9ea3;
    }
  }

  .gallery-swiper.swiper{
    height: 100%;
  }
  /* .gallery-swiper .swiper-wrapper{
    height: calc(100% - 40px);
  } */
  .gallery-swiper .swiper-slide{
    display: flex;
  }

  .portal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 103;
    display: flex;
    justify-content: center;
  }
  .swiper-pagination{
    display: flex;
    display: none;
    gap: 11px;
    justify-content: center;
    margin-top: 10px;
  }
  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active{
    width: 5px;
    height: 5px;
    background: #000;
    display: block;
    opacity: 0.3;
    cursor: pointer;
    border-radius: 50%;
  }
  .swiper-pagination-bullet-active{
    opacity: 1;
  }

  .swiper-fade .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
    opacity: 0;
  } 

  .swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .mapboxgl-canvas-container{
    ${mediaMax('tablet')} {
      width: 100vw;
    }
  }

  .fadeIn {
    transform: translateY(50px);
    opacity: 0;
    transition: all 1s ease;
    &.visible {
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* ----- Safari profile icon ----- */
  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
`

export default GlobalStyle
