import React, { createContext, useState, useEffect } from 'react'

const FirstLoadContext = createContext()

const FirstLoadProvider = ({ children }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  return (
    <FirstLoadContext.Provider value={{ isFirstLoad, setIsFirstLoad }}>
      {children}
    </FirstLoadContext.Provider>
  )
}

const FooterHeightContext = createContext()

const FooterHeightProvider = ({ children }) => {
  const [footerHeight, setFooterHeight] = useState(0)

  useEffect(() => {
    const footerElement = document.getElementById('footer')
    if (footerElement) {
      const updateFooterHeight = () => {
        const height = footerElement.offsetHeight
        setFooterHeight(height)
      }

      updateFooterHeight()

      window.addEventListener('resize', updateFooterHeight)

      return () => {
        window.removeEventListener('resize', updateFooterHeight)
      }
    }
  }, [])

  return (
    <FooterHeightContext.Provider value={footerHeight}>
      {children}
    </FooterHeightContext.Provider>
  )
}

export { FirstLoadProvider, FirstLoadContext, FooterHeightProvider, FooterHeightContext }
